@import '../../../assets/scss/variable.scss';


.custom__class-input-filters {
  max-width: 190px !important;
  width: 100% !important;
}

// ESTO CONTROLA EL TEXTO DE LOS INPUT DE LOS FORM FIELD
::ng-deep .custom__class-input-filters .mat-form-field-infix input {
  font-family: $PPINS !important;
  font-size: 13px !important;
  color: #374357 !important;
}


 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-label {
   color: #565459 !important;
   top: 18px !important;
   margin-top: 0 !important;
 } 

 ::ng-deep .custom__class-input-filters.mat-form-field.mat-form-field-invalid .mat-form-field-label,
 .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
 .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
   color: #565459 !important;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field.mat-focused .mat-form-field-label,
 .mat-form-field.mat-focused .mat-form-field-label.mat-accent,
 .mat-form-field.mat-focused .mat-form-field-label.mat-warn,
 .mat-focused .mat-form-field-required-marker {
   color: #565459 !important;
   font-weight: 500;
 } 
 
 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-infix { 
   padding: 5px 4px 8px 0px !important
 } 

 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-start {
   border: none;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-end {
   border: none;
 } 
  

// ESTE MANTIENE EL COLOR CUANDO SE PRESIONA O SE ESCRIBRIO 
::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-form-field-should-float .mat-form-field-outline {
  background-color: #E2E8F1 !important;
}

 
// ESTE LE CAMBIA EL COLOR AL FONDO CUANDO ES FOCUS
::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  background-color: #F6F6F6 !important;
}

// Este cambia el color del fondo hasta el border cuando es focus, cuando el usuario presiona
::ng-deep  .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  background-color: #F6F6F6 !important;
} 

 ::ng-deep .custom__class-input-filters.mat-input-element {
   color: #374357 !important;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline {
   color: #565459 !important;
   border: 1px solid #D7D5D8 !important;
   border-radius: 8px !important;
 }

 // ESTE COLOR CAMBIA EL ICONO CON EL HOVER
 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-flex:hover {
   color: #565459 !important;
 }

//  ESTE COLOR CAMBIA EL BORDER DEL INPUT
 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  border: 1px solid #1E293B !important;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field-label {
   color: #565459 !important;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field-outline,
 .mat-form-field-empty.mat-form-field-label,
 .mat-form-field-label,
 .mat-form-field-underline,
 .mat-input-element,
 ::placeholder {
   color: #565459 !important;
   font-size: 14px !important;
 }

 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
   color: #ff9196 !important;
 } 


 ::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-gap{
  border: none;
  border-radius: 0;
 }

  ::ng-deep .custom__class-input-filters.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    display: none !important;
 }