.tooltip-inner {
    background-color: #ebf8ff;
    font-family: $PPINS;
    font-size: clamp(0.8rem, 3vw - 1rem, 1em) !important;
    font-weight: 600 !important;
    color: #2a2a2a !important;
    text-align: justify;
    box-shadow: 0px 2px 5px 1px #696969;
    padding: 1.2em;
    white-space: pre-line;
    border-radius: 6px;
    width: max-content;
    min-width: 360px;
}

::ng-deep .tooltip-customized {
    opacity: 1 !important;

    .tooltip-inner {
        line-height: 15px;
        min-width: 500px;
    }

    .arrow::before {
        border-top-color: #ffffff;
        border-bottom-color: #464646;
        bottom: -1px !important;
    }
}

::ng-deep .selectTooltip {
    .tooltip-inner {
        padding-left: 1.5em;
        background-image: url('https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/exclamationWhiteIcon.png');
        background-repeat: no-repeat;
        background-position: 20px 50%;
    }
}