// SweetAlert 2
  h2.titleSwal{
    text-align: center;
    font-family: $PPINS;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  //Buttons
  div.swal2-actions{
    //Confirmation button
    button.confirmButtonSwal{
      width: 177px;
      height: 41px;
      border-radius: 40px;
      background-color: $darkblue!important;
      color: $white;
      text-align: center;
      font-family: $PPINS;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 20.8px */
    }
  }

//Error icon background
.swal2-icon.swal2-error.errorIconSwal{
  background-color: $redAlert2;
  //Error icon cross
  .swal2-x-mark-line-left, .swal2-x-mark-line-right{
    background-color: $white;
  }
}

//Modal container
div.popupSwal{
  border-radius:30px;
}