.flag-icon-background {
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: contain;
  background-size: contain;
}
.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: contain;
  background-size: contain;
}
.flag-icon:before {
  content: '\00a0';
}
.dropdown-menu .flag-icon {
  margin-right: 5px;
}
.flag-icon-ad {
  background-image: url(flags/ad.svg);
}
.flag-icon-ae {
  background-image: url(flags/ae.svg);
}
.flag-icon-af {
  background-image: url(flags/af.svg);
}
.flag-icon-ag {
  background-image: url(flags/ag.svg);
}
.flag-icon-ai {
  background-image: url(flags/ai.svg);
}
.flag-icon-al {
  background-image: url(flags/al.svg);
}
.flag-icon-am {
  background-image: url(flags/am.svg);
}
.flag-icon-ao {
  background-image: url(flags/ao.svg);
}
.flag-icon-aq {
  background-image: url(flags/aq.svg);
}
.flag-icon-ar {
  background-image: url(flags/ar.svg);
}
.flag-icon-as {
  background-image: url(flags/as.svg);
}
.flag-icon-at {
  background-image: url(flags/at.svg);
}
.flag-icon-au {
  background-image: url(flags/au.svg);
}
.flag-icon-aw {
  background-image: url(flags/aw.svg);
}
.flag-icon-ax {
  background-image: url(flags/ax.svg);
}
.flag-icon-az {
  background-image: url(flags/az.svg);
}
.flag-icon-ba {
  background-image: url(flags/ba.svg);
}
.flag-icon-bb {
  background-image: url(flags/bb.svg);
}
.flag-icon-bd {
  background-image: url(flags/bd.svg);
}
.flag-icon-be {
  background-image: url(flags/be.svg);
}
.flag-icon-bf {
  background-image: url(flags/bf.svg);
}
.flag-icon-bg {
  background-image: url(flags/bg.svg);
}
.flag-icon-bh {
  background-image: url(flags/bh.svg);
}
.flag-icon-bi {
  background-image: url(flags/bi.svg);
}
.flag-icon-bj {
  background-image: url(flags/bj.svg);
}
.flag-icon-bl {
  background-image: url(flags/bl.svg);
}
.flag-icon-bm {
  background-image: url(flags/bm.svg);
}
.flag-icon-bn {
  background-image: url(flags/bn.svg);
}
.flag-icon-bo {
  background-image: url(flags/bo.svg);
}
.flag-icon-bq {
  background-image: url(flags/bq.svg);
}
.flag-icon-br {
  background-image: url(flags/br.svg);
}
.flag-icon-bs {
  background-image: url(flags/bs.svg);
}
.flag-icon-bt {
  background-image: url(flags/bt.svg);
}
.flag-icon-bv {
  background-image: url(flags/bv.svg);
}
.flag-icon-bw {
  background-image: url(flags/bw.svg);
}
.flag-icon-by {
  background-image: url(flags/by.svg);
}
.flag-icon-bz {
  background-image: url(flags/bz.svg);
}
.flag-icon-ca {
  background-image: url(flags/ca.svg);
}
.flag-icon-cc {
  background-image: url(flags/cc.svg);
}
.flag-icon-cd {
  background-image: url(flags/cd.svg);
}
.flag-icon-cf {
  background-image: url(flags/cf.svg);
}
.flag-icon-cg {
  background-image: url(flags/cg.svg);
}
.flag-icon-ch {
  background-image: url(flags/ch.svg);
}
.flag-icon-ci {
  background-image: url(flags/ci.svg);
}
.flag-icon-ck {
  background-image: url(flags/ck.svg);
}
.flag-icon-cl {
  background-image: url(flags/cl.svg);
}
.flag-icon-cm {
  background-image: url(flags/cm.svg);
}
.flag-icon-cn {
  background-image: url(flags/cn.svg);
}
.flag-icon-co {
  background-image: url(flags/co.svg);
}
.flag-icon-cr {
  background-image: url(flags/cr.svg);
}
.flag-icon-cu {
  background-image: url(flags/cu.svg);
}
.flag-icon-cv {
  background-image: url(flags/cv.svg);
}
.flag-icon-cw {
  background-image: url(flags/cw.svg);
}
.flag-icon-cx {
  background-image: url(flags/cx.svg);
}
.flag-icon-cy {
  background-image: url(flags/cy.svg);
}
.flag-icon-cz {
  background-image: url(flags/cz.svg);
}
.flag-icon-de {
  background-image: url(flags/de.svg);
}
.flag-icon-dj {
  background-image: url(flags/dj.svg);
}
.flag-icon-dk {
  background-image: url(flags/dk.svg);
}
.flag-icon-dm {
  background-image: url(flags/dm.svg);
}
.flag-icon-do {
  background-image: url(flags/do.svg);
}
.flag-icon-dz {
  background-image: url(flags/dz.svg);
}
.flag-icon-ec {
  background-image: url(flags/ec.svg);
}
.flag-icon-ee {
  background-image: url(flags/ee.svg);
}
.flag-icon-eg {
  background-image: url(flags/eg.svg);
}
.flag-icon-eh {
  background-image: url(flags/eh.svg);
}
.flag-icon-er {
  background-image: url(flags/er.svg);
}
.flag-icon-es {
  background-image: url(flags/es.svg);
}
.flag-icon-et {
  background-image: url(flags/et.svg);
}
.flag-icon-fi {
  background-image: url(flags/fi.svg);
}
.flag-icon-fj {
  background-image: url(flags/fj.svg);
}
.flag-icon-fk {
  background-image: url(flags/fk.svg);
}
.flag-icon-fm {
  background-image: url(flags/fm.svg);
}
.flag-icon-fo {
  background-image: url(flags/fo.svg);
}
.flag-icon-fr {
  background-image: url(flags/fr.svg);
}
.flag-icon-ga {
  background-image: url(flags/ga.svg);
}
.flag-icon-gb {
  background-image: url(flags/gb.svg);
}
.flag-icon-gd {
  background-image: url(flags/gd.svg);
}
.flag-icon-ge {
  background-image: url(flags/ge.svg);
}
.flag-icon-gf {
  background-image: url(flags/gf.svg);
}
.flag-icon-gg {
  background-image: url(flags/gg.svg);
}
.flag-icon-gh {
  background-image: url(flags/gh.svg);
}
.flag-icon-gi {
  background-image: url(flags/gi.svg);
}
.flag-icon-gl {
  background-image: url(flags/gl.svg);
}
.flag-icon-gm {
  background-image: url(flags/gm.svg);
}
.flag-icon-gn {
  background-image: url(flags/gn.svg);
}
.flag-icon-gp {
  background-image: url(flags/gp.svg);
}
.flag-icon-gq {
  background-image: url(flags/gq.svg);
}
.flag-icon-gr {
  background-image: url(flags/gr.svg);
}
.flag-icon-gs {
  background-image: url(flags/gs.svg);
}
.flag-icon-gt {
  background-image: url(flags/gt.svg);
}
.flag-icon-gu {
  background-image: url(flags/gu.svg);
}
.flag-icon-gw {
  background-image: url(flags/gw.svg);
}
.flag-icon-gy {
  background-image: url(flags/gy.svg);
}
.flag-icon-hk {
  background-image: url(flags/hk.svg);
}
.flag-icon-hm {
  background-image: url(flags/hm.svg);
}
.flag-icon-hn {
  background-image: url(flags/hn.svg);
}
.flag-icon-hr {
  background-image: url(flags/hr.svg);
}
.flag-icon-ht {
  background-image: url(flags/ht.svg);
}
.flag-icon-hu {
  background-image: url(flags/hu.svg);
}
.flag-icon-id {
  background-image: url(flags/id.svg);
}
.flag-icon-ie {
  background-image: url(flags/ie.svg);
}
.flag-icon-il {
  background-image: url(flags/il.svg);
}
.flag-icon-im {
  background-image: url(flags/im.svg);
}
.flag-icon-in {
  background-image: url(flags/in.svg);
}
.flag-icon-io {
  background-image: url(flags/io.svg);
}
.flag-icon-iq {
  background-image: url(flags/iq.svg);
}
.flag-icon-ir {
  background-image: url(flags/ir.svg);
}
.flag-icon-is {
  background-image: url(flags/is.svg);
}
.flag-icon-it {
  background-image: url(flags/it.svg);
}
.flag-icon-je {
  background-image: url(flags/je.svg);
}
.flag-icon-jm {
  background-image: url(flags/jm.svg);
}
.flag-icon-jo {
  background-image: url(flags/jo.svg);
}
.flag-icon-jp {
  background-image: url(flags/jp.svg);
}
.flag-icon-ke {
  background-image: url(flags/ke.svg);
}
.flag-icon-kg {
  background-image: url(flags/kg.svg);
}
.flag-icon-kh {
  background-image: url(flags/kh.svg);
}
.flag-icon-ki {
  background-image: url(flags/ki.svg);
}
.flag-icon-km {
  background-image: url(flags/km.svg);
}
.flag-icon-kn {
  background-image: url(flags/kn.svg);
}
.flag-icon-kp {
  background-image: url(flags/kp.svg);
}
.flag-icon-kr {
  background-image: url(flags/kr.svg);
}
.flag-icon-kw {
  background-image: url(flags/kw.svg);
}
.flag-icon-ky {
  background-image: url(flags/ky.svg);
}
.flag-icon-kz {
  background-image: url(flags/kz.svg);
}
.flag-icon-la {
  background-image: url(flags/la.svg);
}
.flag-icon-lb {
  background-image: url(flags/lb.svg);
}
.flag-icon-lc {
  background-image: url(flags/lc.svg);
}
.flag-icon-li {
  background-image: url(flags/li.svg);
}
.flag-icon-lk {
  background-image: url(flags/lk.svg);
}
.flag-icon-lr {
  background-image: url(flags/lr.svg);
}
.flag-icon-ls {
  background-image: url(flags/ls.svg);
}
.flag-icon-lt {
  background-image: url(flags/lt.svg);
}
.flag-icon-lu {
  background-image: url(flags/lu.svg);
}
.flag-icon-lv {
  background-image: url(flags/lv.svg);
}
.flag-icon-ly {
  background-image: url(flags/ly.svg);
}
.flag-icon-ma {
  background-image: url(flags/ma.svg);
}
.flag-icon-mc {
  background-image: url(flags/mc.svg);
}
.flag-icon-md {
  background-image: url(flags/md.svg);
}
.flag-icon-me {
  background-image: url(flags/me.svg);
}
.flag-icon-mf {
  background-image: url(flags/mf.svg);
}
.flag-icon-mg {
  background-image: url(flags/mg.svg);
}
.flag-icon-mh {
  background-image: url(flags/mh.svg);
}
.flag-icon-mk {
  background-image: url(flags/mk.svg);
}
.flag-icon-ml {
  background-image: url(flags/ml.svg);
}
.flag-icon-mm {
  background-image: url(flags/mm.svg);
}
.flag-icon-mn {
  background-image: url(flags/mn.svg);
}
.flag-icon-mo {
  background-image: url(flags/mo.svg);
}
.flag-icon-mp {
  background-image: url(flags/mp.svg);
}
.flag-icon-mq {
  background-image: url(flags/mq.svg);
}
.flag-icon-mr {
  background-image: url(flags/mr.svg);
}
.flag-icon-ms {
  background-image: url(flags/ms.svg);
}
.flag-icon-mt {
  background-image: url(flags/mt.svg);
}
.flag-icon-mu {
  background-image: url(flags/mu.svg);
}
.flag-icon-mv {
  background-image: url(flags/mv.svg);
}
.flag-icon-mw {
  background-image: url(flags/mw.svg);
}
.flag-icon-mx {
  background-image: url(flags/mx.svg);
}
.flag-icon-my {
  background-image: url(flags/my.svg);
}
.flag-icon-mz {
  background-image: url(flags/mz.svg);
}
.flag-icon-na {
  background-image: url(flags/na.svg);
}
.flag-icon-nc {
  background-image: url(flags/nc.svg);
}
.flag-icon-ne {
  background-image: url(flags/ne.svg);
}
.flag-icon-nf {
  background-image: url(flags/nf.svg);
}
.flag-icon-ng {
  background-image: url(flags/ng.svg);
}
.flag-icon-ni {
  background-image: url(flags/ni.svg);
}
.flag-icon-nl {
  background-image: url(flags/nl.svg);
}
.flag-icon-no {
  background-image: url(flags/no.svg);
}
.flag-icon-np {
  background-image: url(flags/np.svg);
}
.flag-icon-nr {
  background-image: url(flags/nr.svg);
}
.flag-icon-nu {
  background-image: url(flags/nu.svg);
}
.flag-icon-nz {
  background-image: url(flags/nz.svg);
}
.flag-icon-om {
  background-image: url(flags/om.svg);
}
.flag-icon-pa {
  background-image: url(flags/pa.svg);
}
.flag-icon-pe {
  background-image: url(flags/pe.svg);
}
.flag-icon-pf {
  background-image: url(flags/pf.svg);
}
.flag-icon-pg {
  background-image: url(flags/pg.svg);
}
.flag-icon-ph {
  background-image: url(flags/ph.svg);
}
.flag-icon-pk {
  background-image: url(flags/pk.svg);
}
.flag-icon-pl {
  background-image: url(flags/pl.svg);
}
.flag-icon-pm {
  background-image: url(flags/pm.svg);
}
.flag-icon-pn {
  background-image: url(flags/pn.svg);
}
.flag-icon-pr {
  background-image: url(flags/pr.svg);
}
.flag-icon-ps {
  background-image: url(flags/ps.svg);
}
.flag-icon-pt {
  background-image: url(flags/pt.svg);
}
.flag-icon-pw {
  background-image: url(flags/pw.svg);
}
.flag-icon-py {
  background-image: url(flags/py.svg);
}
.flag-icon-qa {
  background-image: url(flags/qa.svg);
}
.flag-icon-re {
  background-image: url(flags/re.svg);
}
.flag-icon-ro {
  background-image: url(flags/ro.svg);
}
.flag-icon-rs {
  background-image: url(flags/rs.svg);
}
.flag-icon-ru {
  background-image: url(flags/ru.svg);
}
.flag-icon-rw {
  background-image: url(flags/rw.svg);
}
.flag-icon-sa {
  background-image: url(flags/sa.svg);
}
.flag-icon-sb {
  background-image: url(flags/sb.svg);
}
.flag-icon-sc {
  background-image: url(flags/sc.svg);
}
.flag-icon-sd {
  background-image: url(flags/sd.svg);
}
.flag-icon-se {
  background-image: url(flags/se.svg);
}
.flag-icon-sg {
  background-image: url(flags/sg.svg);
}
.flag-icon-sh {
  background-image: url(flags/sh.svg);
}
.flag-icon-si {
  background-image: url(flags/si.svg);
}
.flag-icon-sj {
  background-image: url(flags/sj.svg);
}
.flag-icon-sk {
  background-image: url(flags/sk.svg);
}
.flag-icon-sl {
  background-image: url(flags/sl.svg);
}
.flag-icon-sm {
  background-image: url(flags/sm.svg);
}
.flag-icon-sn {
  background-image: url(flags/sn.svg);
}
.flag-icon-so {
  background-image: url(flags/so.svg);
}
.flag-icon-sr {
  background-image: url(flags/sr.svg);
}
.flag-icon-ss {
  background-image: url(flags/ss.svg);
}
.flag-icon-st {
  background-image: url(flags/st.svg);
}
.flag-icon-sv {
  background-image: url(flags/sv.svg);
}
.flag-icon-sx {
  background-image: url(flags/sx.svg);
}
.flag-icon-sy {
  background-image: url(flags/sy.svg);
}
.flag-icon-sz {
  background-image: url(flags/sz.svg);
}
.flag-icon-tc {
  background-image: url(flags/tc.svg);
}
.flag-icon-td {
  background-image: url(flags/td.svg);
}
.flag-icon-tf {
  background-image: url(flags/tf.svg);
}
.flag-icon-tg {
  background-image: url(flags/tg.svg);
}
.flag-icon-th {
  background-image: url(flags/th.svg);
}
.flag-icon-tj {
  background-image: url(flags/tj.svg);
}
.flag-icon-tk {
  background-image: url(flags/tk.svg);
}
.flag-icon-tl {
  background-image: url(flags/tl.svg);
}
.flag-icon-tm {
  background-image: url(flags/tm.svg);
}
.flag-icon-tn {
  background-image: url(flags/tn.svg);
}
.flag-icon-to {
  background-image: url(flags/to.svg);
}
.flag-icon-tr {
  background-image: url(flags/tr.svg);
}
.flag-icon-tt {
  background-image: url(flags/tt.svg);
}
.flag-icon-tv {
  background-image: url(flags/tv.svg);
}
.flag-icon-tw {
  background-image: url(flags/tw.svg);
}
.flag-icon-tz {
  background-image: url(flags/tz.svg);
}
.flag-icon-ua {
  background-image: url(flags/ua.svg);
}
.flag-icon-ug {
  background-image: url(flags/ug.svg);
}
.flag-icon-um {
  background-image: url(flags/um.svg);
}
.flag-icon-us {
  background-image: url(flags/us.svg);
}
.flag-icon-uy {
  background-image: url(flags/uy.svg);
}
.flag-icon-uz {
  background-image: url(flags/uz.svg);
}
.flag-icon-va {
  background-image: url(flags/va.svg);
}
.flag-icon-vc {
  background-image: url(flags/vc.svg);
}
.flag-icon-ve {
  background-image: url(flags/ve.svg);
}
.flag-icon-vg {
  background-image: url(flags/vg.svg);
}
.flag-icon-vi {
  background-image: url(flags/vi.svg);
}
.flag-icon-vn {
  background-image: url(flags/vn.svg);
}
.flag-icon-vu {
  background-image: url(flags/vu.svg);
}
.flag-icon-wf {
  background-image: url(flags/wf.svg);
}
.flag-icon-ws {
  background-image: url(flags/ws.svg);
}
.flag-icon-ye {
  background-image: url(flags/ye.svg);
}
.flag-icon-yt {
  background-image: url(flags/yt.svg);
}
.flag-icon-za {
  background-image: url(flags/za.svg);
}
.flag-icon-zm {
  background-image: url(flags/zm.svg);
}
.flag-icon-zw {
  background-image: url(flags/zw.svg);
}
