@import '../fonts/fonts.scss';

//BLUE

.button-blue-semirounded-1 {
	font-family: $munilyFamily;
	color: white;
	font-size: $munilyFontParrSize4;
	font-weight: bold;
	background: #388AED;
	border: none;
	padding: 7px 20px;
	border-radius: 8px;
}

.button-blue-1 {
  	font-family: $munilyFamily;
	color: white;
	font-size: $munilyFontParrSize4;
	font-weight: bold;
	background: #388AED;
	border: none;
	padding: 7px 20px;
	border-radius: 22px;
}

.button-blue-outline-1 {
	font-family: $munilyFamily;
	color: #388AED;
	font-size: $munilyFontParrSize4;
	font-weight: bold;
	background: white;
	border: 1px solid #03325B;
	padding: 7px 20px;
	border: 1px solid #388AED;
	border-radius: 22px;
}


//DARKBLUE

.button-darkblue-1 {
	font-family: $munilyFamily;
	color: white;
	font-size: $munilyFontParrSize4;
	font-weight: bold;
	background: #03325B;
	border: none;
	padding: 7px 20px;
	border-radius: 22px;
  &:disabled{
	  background: #03325b7d;
    cursor: no-drop;
  }
}

.button-darkblue-outline-1 {
	font-family: $munilyFamily;
	color: #03325B;
	font-size: $munilyFontParrSize4;
	font-weight: bold;
	background: white;
	border: none;
	border: 1px solid #03325B;
	padding: 7px 20px;
	border-radius: 22px;
}