// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
.available-hours-modal {
    .modal-dialog {
        @media (min-width: 1600px) {
            max-width: 1140px;
        }
    }
    .modal-content {
        overflow: hidden;
        border-radius: 30px;
        border: solid 3px #5698cc !important;
        background-color: #ffffff !important;
    }
    .modal-header {
        padding-top: 30px;
        padding-bottom: 30px;
        justify-content: flex-end;

        button {
            padding: 10px 30px;
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
    .modal-body {
        height: 80vh;
        padding: 0px;
        overflow-y: scroll;
        text-align: center;

        // .table-responsive
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        th {
            font-weight: 700;
        }
        tbody {
            th {
                padding: 5px 20px;
            }
        }
        .clickable-cell {
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
                background-color: #f1f1f1;
            }
            &.active {
                background-color: #327af2;
            }
        }
    }
}

.readonly {
  cursor: not-allowed;
  pointer-events: none;
}

table td{
  vertical-align: middle!important;
}
