@import '../colors/colors.scss';

.grey-rounded-scroll{
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 1em;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}