@import '../fonts/fonts_n.scss';


//Primary
button.btn_primary_text{
  
}


//Secondary >>>>
@mixin btn_secondary_text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 40px;
  width: auto;
  max-width: 328px;
  padding: 8px 16px 8px 16px;
  border-radius: 360px;
  font-family: $PPINSL;
  font-size: 13px;
  font-weight: 500;
  color: $grey_color_80;
  .mat-button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  &:disabled{
    color: $grey_color_50;
    cursor: no-drop;
  }
  &:hover{
    .mat-button-focus-overlay{
      background:$green_status_color_100;
    }
  }
  &:active, &:focus{
    .mat-button-focus-overlay{
      background:$green_status_color_100;
    }
  }
}

button.btn_secondary_text{
  @include btn_secondary_text;
}

button.btn_secondary_text__icon_left{
  @include btn_secondary_text;
  padding: 8px 16px 8px 12px;
  flex-flow: reverse;
}

button.btn_secondary_text__icon_rigth{
  @include btn_secondary_text;
  padding: 8px 12px 8px 16px;
}

//Secondary >>>>
@media screen and (max-width: 1024px){
  button.btn_secondary_text__icon_left{
    .mat-button-wrapper :not(:first-child){
      display: none;
    }
  }
  button.btn_secondary_text__icon_rigth{
    padding: 8px 16px 8px 16px;
    .mat-button-wrapper{ 
      :first-child{
        display: none;
      }
    }
  }
}
