@import '../fonts/fonts.scss';

.label-1 {
  font-family: $munilyFamily;
  color: $munilyFontColorBold;
  font-size: $munilyFontParrSize4;
  font-weight: 700;
}

.label-blue-1 {
  font-family: $munilyFamily;
  color: $munilyBlueColor;
  font-size: $munilyFontParrSize4;
  font-weight: 700;
}

.header-text-with-prefix-icon{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding-left: 12px;
  padding-right: 16px;
}
