/* Material Icons Round */
@font-face {
  font-family: 'Material Icons Outlined';
  src: url('./MaterialIconsOutlined-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined'!important;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: inherit;
  line-height: 1;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  text-transform: none;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}