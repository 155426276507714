//////////////////////////////////////////////////// Typography

/// Después del número siempre va una letra, esta es la leyenda::after
/// b = BOLD
/// s = SEMI-BOLD
/// m = medium
/// c = complementary
/// u = underline

///////////////////// HEADING

////// H1

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

////// H2

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

////// H3

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

////// H4

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

////// H5

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

///////////////////// SUB-HEADING

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

///////////////////// PARAGRAPH

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

///////////////////// Labels

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

////////////////////// OLD

// Family
$munilyFamily: 'Poppins';
$munilyFontColor: #333333;
$munilyBlueColor: #388aed;
$munilyFontColorBold: #3e3e3e;
$munilyGrayColor: rgba(53, 52, 52, 0.5);
$munilyDarkColor: #414350;

* {
  font-family: $munilyFamily;
}

// Titles

$munilyFontTitleSize01: 17px;
$munilyFontTitleSize1: 27px;
$munilyFontTitleSize2: 21px;
$munilyFontTitleSize3: 35px;

//Text

$munilyFontParrSize4: 15px;
$munilyFontParrSize3: 14px;

.title-01 {
  font-family: $munilyFamily;
  color: $munilyFontColor;
  font-size: $munilyFontTitleSize01;
  font-weight: 600;
}

.title-3 {
  font-family: $munilyFamily;
  color: $munilyFontColorBold;
  font-size: $munilyFontTitleSize3;
  font-weight: 700;
}

.title-2 {
  font-family: $munilyFamily;
  color: $munilyFontColor;
  font-size: $munilyFontTitleSize2;
  font-weight: 600;
}

.title-1 {
  font-family: $munilyFamily;
  color: $munilyFontColorBold;
  font-size: $munilyFontTitleSize1;
  font-weight: 700;
}

.parr-4 {
  font-size: $munilyFontParrSize4;
}

.parr-3 {
  color: $munilyGrayColor;
}

.parr-2 {
  font-size: $munilyFontParrSize3;
  color: $munilyDarkColor;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: $munilyBlueColor;
  font-weight: bold;
}
