@import './../colors/colors.scss';
@import '../../../assets/scss/variable.scss';


.custom__status{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 20px;
  border-radius: 16px;
  font-family: $PPINS;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $grey_color_100;
  margin: auto;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  flex-wrap: nowrap;
  &.active, &.entered, &.created{
    background-color: $blue_color_20;
  }
  &.time-exceeded, &.completed-by-system, &.canceled, &.false-alarm, &.noCompleted{
    background-color: $red_status_color_20;
  }
  &.displacement, &.unresolve {
    background-color: $yellow_status_color_20;
  }
  &.completed, &.left, &.resolve, &.attended{
    background-color: $green_status_color_30;
  }
}