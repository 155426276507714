@font-face {
  font-family: "SFcompact";
  src: url("../../../assets/scss/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("../../../assets/scss/fonts/sf-compact-display-thin.otf");
  
}
// Poppins
@font-face {
  font-family: "PoppinsLight";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Thin.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Bold.otf");
}
//WORKSANS
@font-face {
  font-family: "WorkSansLight";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}

//FONTS
$SFC : 'SFcompact';
$SFCL : 'SFcompactLight';
$PPINS : 'PoppinsRegular';
$PPINST: 'PoppinsThin';
$PPINSL: 'PoppinsLight';
$PPINSB: 'PoppinsBold';
$WsansL: 'WorkSansLigh';
$WsansSB: 'WorkSansSemiBold';

.poppinsR{
  font-family: $PPINS;
}
.poppinsT{
  font-family: $PPINST;
}
.poppinsL{
  font-family: $PPINSL;
}
.poppinsB{
  font-family: $PPINSB;
}