@import '../fonts/fonts_n.scss';

//H1 CLASSES >>>>>
@mixin h1_style($weight) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: 5.8rem; // 93px
  line-height: 130%;
  letter-spacing: -1.5%;
}

$h1_weights: (
  300: h1_300,
  400: h1_400,
  500: h1_500,
  600: h1_600,
  700: h1_700
);

@each $weight, $class in $h1_weights {
  .#{$class} {
    @include h1_style($weight);
  }
}

//H2 CLASSES >>>>>
@mixin h2_style($weight, $letter-spacing:-1.5%) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: 3.6rem; // 58px
  line-height: 130%;
  letter-spacing: $letter-spacing;
}

$h2_weights: (
  300: (-1.5%, h2_300),
  400: (-1.5%, h2_400),
  500: (-1.5%, h2_500),
  600: (-0.5%, h2_600),
  700: (-0.5%, h2_700)
);

@each $weight, $details in $h2_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);

  .#{$class} {
    @include h2_style($weight, $letter-spacing);
  }
}

//H3 CLASSES >>>>>
@mixin h3_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
}

$h3_weights: (
  300: (-0.5%, h3_300, 2.8rem), //46px
  400: (-0.5%, h3_400, 2.0rem), //33px
  500: (-0.5%, h3_500, 1.4rem), //23px
  600: (0%, h3_600, 1.1rem),    //19px
  700: (0%, h3_700, 1.1rem)     //19px
);

@each $weight, $details in $h3_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include h3_style($weight, $letter-spacing, $font-size);
  }
}

//H4 CLASSES >>>>>
@mixin h4_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
}

$h4_weights: (
  300: (0.25%, h4_300, 2.0rem), //33px
  400: (0.25%, h4_400, 2.0rem), //33px
  500: (0.25%, h4_500, 2.0rem), //33px
  600: (0.25%, h4_600, 2.0rem), //33px
  700: (0.25%, h4_700, 2.0rem)  //33px
);

@each $weight, $details in $h4_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include h4_style($weight, $letter-spacing, $font-size);
  }
}

//H5 CLASSES >>>>>
@mixin h5_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
}

$h5_weights: (
  300: (0%, h5_300, 1.4rem), //23px
  400: (0%, h5_400, 1.4rem), //23px
  500: (0%, h5_500, 1.4rem), //23px
  600: (0%, h5_600, 1.4rem), //23px
  700: (0%, h5_700, 1.4rem)  //23px
);

@each $weight, $details in $h5_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include h5_style($weight, $letter-spacing, $font-size);
  }
}

//H6 CLASSES >>>>>
@mixin h6_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
  color: $neutral_black;
  &.secondary{
    color: $grey_color_80!important;
  }
}

$h6_weights: (
  300: (0, h6_300, 1.1rem), //19px
  400: (0, h6_400, 1.1rem), //19px
  500: (0, h6_500, 1.1rem), //19px
  600: (0, h6_600, 1.1rem), //19px
  700: (0, h6_700, 1.1rem)  //19px
);

@each $weight, $details in $h6_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include h6_style($weight, $letter-spacing, $font-size);
  }
}

//SUBTITLE 1 CLASSES >>>>>
@mixin s1_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
  color: $neutral_black;
  &.secondary{
    color: $grey_color_50!important;
  }
}

$s1_weights: (
  300: (0.15%, s1_300, 0.9rem), //15px
  400: (0.15%, s1_400, 0.9rem), //15px
  500: (0.15%, s1_500, 0.9rem), //15px
  600: (0.15%, s1_600, 0.9rem), //15px
  700: (0.15%, s1_700, 0.9rem)  //15px
);

@each $weight, $details in $s1_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include s1_style($weight, $letter-spacing, $font-size);
  }
}

//SUBTITLE 2 CLASSES >>>>>
@mixin s2_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
  color: $neutral_black;
  &.secondary{
    color: $grey_color_50!important;
  }
}

$s2_weights: (
  300: (0.1%, s2_300, 0.8rem), //13px
  400: (0.1%, s2_400, 0.8rem), //13px
  500: (0.1%, s2_500, 0.8rem), //13px
  600: (0.1%, s2_600, 0.8rem), //13px
  700: (0.1%, s2_700, 0.8rem)  //13px
);

@each $weight, $details in $s2_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include s2_style($weight, $letter-spacing, $font-size);
  }
}

//BODY 1 CLASSES >>>>>
@mixin b1_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
  color: $neutral_black;
  &.secondary{
    color: $grey_color_50!important;
  }
}

$b1_weights: (
  300: (0.5%, b1_300, 0.9rem), //15px
  400: (0.5%, b1_400, 0.9rem), //15px
  500: (0.5%, b1_500, 0.9rem), //15px
  600: (0.5%, b1_600, 0.9rem), //15px
  700: (0.5%, b1_700, 0.9rem)  //15px
);

@each $weight, $details in $b1_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include b1_style($weight, $letter-spacing, $font-size);
  }
}

//BODY 2 CLASSES >>>>>
@mixin b2_style($weight, $letter-spacing:-0.5%, $font-size) {
  font-family: $PPINS;
  font-weight: $weight;
  font-size: $font-size;
  line-height: 130%;
  letter-spacing: $letter-spacing;
  color: $neutral_black;
  &.secondary{
    color: $grey_color_50!important;
  }
}

$b2_weights: (
  300: (0.25%, b2_300, 0.8rem), //13px
  400: (0.25%, b2_400, 0.8rem), //13px
  500: (0.25%, b2_500, 0.8rem), //13px
  600: (0.25%, b2_600, 0.8rem), //13px
  700: (0.25%, b2_700, 0.8rem)  //13px
);

@each $weight, $details in $b2_weights {
  $letter-spacing: nth($details, 1);
  $class: nth($details, 2);
  $font-size: nth($details, 3);

  .#{$class} {
    @include b2_style($weight, $letter-spacing, $font-size);
  }
}

p.subtitle-1, a.subtitle-1, span.subtitle-1{
  //styleName: texts/subtile-1;
  font-family: $PPINS;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.0015em;
  color: $grey_color_50;
}

.subtitle-2{
  font-family: $PPINSL;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.9px;
  letter-spacing: 0.001em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $grey_color_50;
}

.body-1{
  //styleName: texts/body-1;
  font-family: $PPINS;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $grey_color_50;
}

 .body-2{
  font-family: $PPINS;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.9px;
  letter-spacing: 0.0025em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $neutral_black;
}

.body-2-Pt{
  @extend .body-2;
  font-family: $PPINST;
}

//Text alignment
.txt-c{
  text-align: center;
}

