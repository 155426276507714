@import '../fonts/fonts.scss';

* {
  font-family: $munilyFamily;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 990 !important;
  background-color: rgba(00, 00, 00, 0.7) !important;
  transition: opacity 1.5s;
}

.modal-container-1 {
  margin-top: 25vh;
  margin-left: 30vw;
  z-index: 991;
  width: 40vw;
  height: 5vh;
  opacity: 1;
}
