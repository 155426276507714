$tooltipCianColor: #ebf8ff;

// Parent of tooltip (Global)
.have-tooltip {
  position: relative;
  display: inline-block;
}

.have-tooltip:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

// Tooltip himself (Global)
.tooltip {
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

// 1. Tooltip Colors
.tooltip.cian {
  background-color: $tooltipCianColor;
  color: #2a2a2a;
}

.tooltip.cian::after {
  border-color: $tooltipCianColor transparent transparent transparent;
}

// 2. Tooltip Directions
.tooltip.right {
  bottom: -120%;
  left: 164%;
  margin-left: -60px;
}

.up {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

// 3. Tooltip Arrows
.tooltip.right::after {
  content: '';
  position: absolute;
  top: 34%;
  left: -4%;
  border-width: 5px;
  border-style: solid;
  width: 13px;
  transform: rotate(90deg);
}

.tooltip.up::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
