@import '../fonts/fonts.scss';

$inputBorderPrimary: #e8e8ea;
$inputStandardBorder: 1px solid $inputBorderPrimary;
$inputStandardBorderError: 1px solid $inputBorderPrimary;

.input-1 {
  border: 1px solid #e8e8ea;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  width: 100%;
  height: 38px;
}

.input-1::placeholder {
  font-weight: 400;
  color: #909090;
}

.input-1.error {
  border-color: rgba(255, 0, 0, 0.4);
}

// SELECT

.input-select {
  width: 100%;
  border: $inputStandardBorder;
  border-radius: 10px;
  color: black;
}

.input-select > .ng-select-container {
  background-color: white !important;
}

.input-select > .ng-select-container > .ng-value-container > .ng-placeholder {
  font-weight: 400 !important;
  color: #909090 !important;
}

.input-select.error {
  border: $inputStandardBorderError !important;
}

// SWITCH

$switchColorPrimary: #7fd77d;

.container-switch::ng-deep .mat-slide-toggle-thumb-container {
  top: -2px;
}

.container-switch::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $switchColorPrimary;
  border-radius: 30px;
  height: 20px;
}

.container-switch::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
  width: 14px;
  height: 14px;
  transform: translate(18%, 38%);
}

.container-switch::ng-deep .mat-slide-toggle-content {
  font-size: 15px;
  font-family: $munilyFamily;
  color: $munilyFontColor;
  font-weight: 400;
}
