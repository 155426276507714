//Definitions Source:
// https://www.figma.com/design/nZEfdJ8pN0ILY9cAkO3DSG/Munily-Design-System?node-id=2111-30801&node-type=frame&t=KB34ebrXVf8zgLD2-0

//PRIMARY COLORS >>>>>>>>>>>>>>>>>>>
//For CTA’s, buttons, links, headers, icons, and progress bars.
  //BLUES>>>
    $blue_color_100 : #1E5EA4;   // primary/blue/b-100
    $blue_color_90 : #2367B3;    // primary/blue/b-90
    $blue_color_80 : #2870C1;    // primary/blue/b-80
    $blue_color_70 : #2E78D0;    // primary/blue/b-70
    $blue_color_60 : #3381DE;    // primary/blue/b-60
    $blue_color_50 : #388AED;    // primary/blue/b-50
    $blue_color_40 : #66A5F1;    // primary/blue/b-40
    $blue_color_30 : #95C1F6;    // primary/blue/b-30
    $blue_color_20 : #C3DCFA;    // primary/blue/b-20
    $blue_color_10 : #DCEEFF;    // primary/blue/b-10
  //BLUES<<<
//PRIMARY COLORS >>>>>>>>>>>>>>>>>>>

//NEUTRALS >>>>>>>>>>>>>>>>>>>>>
//For titles and paragraphs texts. Also in separator lines and in the borders of text inputs.
  //LIGHT GREYS >>>
    $grey_color_100: #1E293B;  // secundary/s-100
    $grey_color_90: #2A3548;   // secundary/s-90
    $grey_color_80: #374357;   // secundary/s-80
    $grey_color_70: #465267;   // secundary/s-70
    $grey_color_60: #586479;   // secundary/s-60
    $grey_color_50: #6B778C;   // secundary/s-50
    $grey_color_40: #7F8BA1;   // secundary/s-40
    $grey_color_30: #94A2B7;   // secundary/s-30
    $grey_color_20: #CAD4E0;   // secundary/s-20
    $grey_color_10: #E2E8F1;   // secundary/s-10
  //LIGHT GREYS <<<
  //DARK GREYS >>>
    $darkgrey_color_100: #000000; // neutral/dark/d-100
    $darkgrey_color_90: #121317;  // neutral/dark/d-90
    $darkgrey_color_80: #26262B; // neutral/dark/d-80
    $darkgrey_color_70: #3C3B41; // neutral/dark/d-70
    $darkgrey_color_60: #565459; // neutral/dark/d-60
    $darkgrey_color_50: #726F75; // neutral/dark/d-50
    $darkgrey_color_40: #8F8C92; // neutral/dark/d-40
    $darkgrey_color_30: #B2AFB3; // neutral/dark/d-30
    $darkgrey_color_20: #D7D5D8; // neutral/dark/d-20
    $darkgrey_color_10: #F6F6F6; // neutral/dark/d-10
  //DARK GREYS <<<
  $neutral_white: #FFFFFF;
  $neutral_black: $darkgrey_color_100;
//NEUTRALS <<<<<<<<<<<<<<<<<<<<<<

//SYSTEM STATUS >>>>>>>>>>>>>>>>>>
//Use it on notifications, errors, wanrings, and success mesagges on modals assets.
  //GREENS - SUCCESS >>>
    $green_status_color_100:#369752; // system-status/green/g-100
    $green_status_color_90: #38A262; // system-status/green/g-90
    $green_status_color_80: #3BAD72; // system-status/green/g-80
    $green_status_color_70: #3DB881; // system-status/green/g-70
    $green_status_color_60: #40C391; // system-status/green/g-60
    $green_status_color_50: #42CEA1; // system-status/green/g-50
    $green_status_color_40: #71DBB9; // system-status/green/g-40
    $green_status_color_30: #A0E7D0; // system-status/green/g-30
    $green_status_color_20: #BDEEDE; // system-status/green/g-20
    $green_status_color_10: #D9F5EC; // system-status/green/g-10
  //GREENS - SUCCESS <<<

  //YELLOWS  - WARNING >>>
    $yellow_status_color_100: #BD6F12; //system-status/yellow/y-100
    $yellow_status_color_90: #D88526; //system-status/yellow/y-90
    $yellow_status_color_80: #F29B3A; //system-status/yellow/y-80
    $yellow_status_color_70: #FCBB5B; //system-status/yellow/y-70
    $yellow_status_color_60: #FCC26B; //system-status/yellow/y-60
    $yellow_status_color_50: #FCC97C; //system-status/yellow/y-50
    $yellow_status_color_40: #FDCF8C; //system-status/yellow/y-40
    $yellow_status_color_30: #FDD69D; //system-status/yellow/y-30
    $yellow_status_color_20: #FDDDAD; //system-status/yellow/y-20
    $yellow_status_color_10: #FEE8C8; //system-status/yellow/y-10
  //YELLOWS  - WARNING <<<

  //REDS  - ERROR >>>
    $red_status_color_100: #BB2A3E; //system-status/red/r-100
    $red_status_color_90: #C93749; //system-status/red/r-90
    $red_status_color_80: #D74553; //system-status/red/r-80
    $red_status_color_70: #E5525E; //system-status/red/r-70
    $red_status_color_60: #F35F68; //system-status/red/r-60
    $red_status_color_50: #F9787F; //system-status/red/r-50
    $red_status_color_40: #FF9196; //system-status/red/r-40
    $red_status_color_30: #FEB3B6; //system-status/red/r-30
    $red_status_color_20: #FFCED0; //system-status/red/r-20
    $red_status_color_10: #FFF1F1; //system-status/red/r-10
  //REDS  - ERROR <<<
//SYSTEM STATUS <<<<<<<<<<<<<<<<<


