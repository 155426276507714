
@import '../fonts/fonts.scss';
// @use '@angular/material' as mat;


.field_text_large{
  text-align: left;
  //variables
  --mdc-outlined-text-field-container-shape:8px; //border
  --mat-optgroup-label-text-font:$PPINSB;
  --mdc-outlined-text-field-label-text-font:$PPINSB;
  --mdc-outlined-text-field-outline-color: #565459;
  --mat-form-field-container-height:44px;
  --mdc-outlined-text-field-label-text-font:$PPINSB;
  .mat-mdc-form-field-flex{
    height:44px;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
    font-family: 'PoppinsRegular';
    font-size: 15px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 0.0015em;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label{
    color: #565459!important;
  }
  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix{
    padding: 1px;
    display: flex;
    align-items: center;
  }
}

