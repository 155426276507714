@import "../labels/text-tags.scss";

mat-sidenav-container.principal-menu{
  height: 100dvh;
  // z-index: 55;//Testint

  .mat-drawer-inner-container{
    display: flex;
    flex-flow: column;
    overflow: hidden;
  }
  
  mat-sidenav{
    height: 100%;
    width: 212px;
    background-color: $blue_color_70;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
    transition: all 0.3s ease;
    .menu-header{
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      flex-direction: column;
      margin: 24px 0 20px 0;
      padding: 0 12px;
      .toggle-menu{
        border-radius: 50px;
        align-self: flex-end;
        padding: 0;
        min-width: auto;
        width: auto;
        mat-icon{
          color: $neutral_white;
        }
      }
      img.logo{
        height: 40px;
        width: auto;
        align-self: center;
      }
    }

    .mat-nav-list.mat-nav-list{
      flex: 1 1 auto;
      overflow-y: auto;
      margin-right: 6px;
      mat-list-item.mat-list-item{
        height: 40px;
        width: calc(100% - 8px);
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        transition: all 0.2s ease;
        margin-bottom: 4px;
        .mat-ripple{
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
        .mat-list-item-content{
          padding: 0 10px;
        }
        a.sidebar-link{
          display: flex;
          align-items: center;
          gap: 4px;
          color: $neutral_white;
          font-family: $PPINSL;
          font-size: 13px;
          font-weight: 400;
          line-height: 16.9px;
          letter-spacing: 0.0025em;
          text-align: left;
          mat-icon{
            display: flex;
            align-items: center;
            font-size: 20px;
          }
          span {
            transition: opacity 0.3s ease, visibility 0.3s ease;
            white-space: nowrap;
          }
        }
        &.active{
          background: $blue_color_100;
          &::before{
            content: '';
            position: absolute;
            height: inherit;
            width: 4px;
            background-color: $blue_color_30;
          }
        }
        &:hover{
          background: $blue_color_90;
        }
        
      }
      // SUBMENU ITEMS
      mat-list-item.mat-list-item:has(mat-nav-list){
        height: auto!important;
        > .mat-ripple{
          display: none;
        }
        .mat-list-item-content{
          padding: 0;
        }
        .submenu{
          .submenu-name{
            padding-left: 10px;
          }
          .second-level{
            .sidebar-link{
              padding-left: 48px;    
            }
          }
        }
        
        .submenu-name{
          min-height: 40px;
        }
        &:hover{
          background: none;
        }
      }
    }

    //RAIL MODE
    &.rail-mode {
      width: 68px; /* Ancho del rail */
      .toggle-menu{
        align-self: center!important;
      }
      mat-nav-list.mat-nav-list{
        padding: 8px 6px 0px 8px;
        height: 70%;
        mat-list-item.mat-list-item{
          height: 40px;
          width: 44px;
          span {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }

  mat-sidenav.mat-sidenav > div > mat-nav-list.mat-nav-list{
    padding-bottom: 36px;
  }

  mat-sidenav-content {
    transition: margin-left 0.3s ease;
    &.rail-mode{
      margin-left: 68px!important;
    }
    &.drawer-mode{
      margin-left: 212px!important;
    }
  }
}


mat-nav-list.mat-nav-list{
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey_color_20;
    border-radius: 1em;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $neutral_white;
  }
}