@import '../fonts/fonts.scss';

.mini-card-1 {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 190px;
  height: 100px;
  padding: 15px;
  text-align: center;
}

.mini-card-1.borderless {
  box-shadow: none;
  border: none;
  width: 190px;
  height: 100px;
  padding: 15px;
  text-align: center;
  opacity: 0.5;
}
